import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import rehypeReact from "rehype-react"
import {
  CourseSignupForm,
  GenericSignupForm,
  WebpackReduceBundleSignupForm,
  CodeYourOwnReduxSignupForm,
  SendMeParcelCodeSignupForm,
  TryParcelSignupForm,
  GetCodeSignupForm,
} from "../components/SignupForm"
import Img from "gatsby-image"
import * as styles from "../components/SignupForm.module.css"
require("prismjs/themes/prism-tomorrow.css")
var kebabCase = require("lodash.kebabcase")

const WebpackBookMiniCTA = () => (
  <div className={styles.signupFormArea}>
    <h2>Learn webpack - an ebook for webpack beginners</h2>
    <div style={{ textAlign: "left" }}>
      <p>
        Stop banging your head against the <code>webpack.config.js</code>. It's
        time to learn webpack properly.
      </p>
      <p>
        With my ebook you'll learn how to build fast, well organized and modern
        web apps with webpack. Take your frontend skills to the next level and
        finally learn webpack deeply.{" "}
        <a href="https://createapp.dev/webpack-book?utm_campaign=blog">
          Learn more about the book here.
        </a>
      </p>
    </div>
    <button
      className={styles.myButton}
      onClick={() =>
        (window.location =
          "https://createapp.dev/webpack-book#buy?utm_campaign=blog")
      }
    >
      Buy now
    </button>
    <br />
    <br />
  </div>
)

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const tags = post.frontmatter.tags || []
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1>{post.frontmatter.title}</h1>
        {post.frontmatter.featuredImage ? (
          <Img
            alt={post.frontmatter.title}
            sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
            style={{ marginBottom: "2rem" }}
          />
        ) : null}
        {renderAst(post.htmlAst)}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <div>
          tags:
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-around`,
              listStyle: `none`,
            }}
          >
            {tags.map(t => (
              <li key={kebabCase(t)}>
                <Link to={`/tags/${kebabCase(t)}`}>{t}</Link>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`
